import styled from 'styled-components';

const NoHeroWrapper = styled.div`
  max-width: 40em;
  margin: 9em auto 3em;
  text-align: center;
  position: relative;
  z-index: 2;
  h1 {
    color: ${props => props.theme.colors.global.base.light};
  }
`;

export default NoHeroWrapper;
