import React from 'react';

import { NoHeroWrapper, Breadcrumbs, Description, Diamond } from './files';

const NoHero = ({ seoContent, type }) => {
  const { menuTitle, pageTitle, description } = seoContent;
  return (
    <NoHeroWrapper>
      <Breadcrumbs type={type} title={menuTitle} />
      <h1>{pageTitle}</h1>
      <Description description={description} />
      <Diamond />
    </NoHeroWrapper>
  );
};

export default NoHero;
