import styled from 'styled-components';

const StoriesGrid = styled.div`
  display: block;
  flex-wrap: wrap;
  &.first-page {
    display: block;
    a {
      &:first-child {
        > div {
          width: calc(50% - 1em);
          height: calc(37em - 0.5px);
          @media (max-width: ${props => props.theme.responsive.small}) {
            width: 100%;
            height: 18em;
          }
        }
      }
    }
  }
`;

export default StoriesGrid;
