import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

// Components
import { StoryCategoriesWrapper } from './files';

const Categories = ({ currentSlug }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulStoryTags(sort: { fields: [menuOrder], order: ASC }) {
          edges {
            node {
              id
              seoContent {
                menuTitle
                slug
              }
              menuOrder
            }
          }
        }
      }
    `}
    render={data => {
      const categories = data.allContentfulStoryTags.edges;
      const slugs = categories.map(({ node: tags }) => tags.seoContent.slug);
      return (
        <StoryCategoriesWrapper>
          <h3>Filter By:</h3>
          <Link
            className={
              slugs.some(slug => currentSlug && currentSlug.match(slug))
                ? ''
                : 'active'
            }
            to="/stories/"
            title="All Mountain Whispers Stories"
          >
            All
          </Link>
          {data.allContentfulStoryTags.edges.map(({ node: tag }) => {
            const activeClass =
              currentSlug && currentSlug.includes(tag.seoContent.slug);
            return (
              <Link
                key={tag.id}
                className={activeClass ? 'active' : ''}
                activeClassName="active"
                to={`/stories/${tag.seoContent.slug}/`}
                title={tag.seoContent.menuTitle}
              >
                {tag.seoContent.menuTitle}
              </Link>
            );
          })}
        </StoryCategoriesWrapper>
      );
    }}
  />
);

export default Categories;
