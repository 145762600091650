import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoContent';
import NoHero from '../components/global/noHero';
import StoriesIndex from '../components/pages/stories';
import Categories from '../components/global/storyCategories';

// Context
import ContextConsumer from '../components/common/contextProvider';

const StoriesTemplate = ({ data, pageContext }) => (
  <ContextConsumer>
    {({ context }) => (
      <Content data={data} pageContext={pageContext} context={context} />
    )}
  </ContextConsumer>
);

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, pageContext } = this.props;
    const { seoContent } = data.contentfulPages;
    return (
      <Fragment>
        <SeoDetails seoContent={seoContent} />
        <NoHero type="stories" seoContent={seoContent} />
        <Categories />
        <StoriesIndex pageContext={pageContext} data={data} />
      </Fragment>
    );
  }
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    contentfulPages(id: { eq: "ce457e47-8a58-5831-a122-23243f52e379" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          description
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    allContentfulStories(
      sort: { fields: [datePosted], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          text {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 60)
            }
          }
          featuredImage {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          categories {
            id
            seoContent {
              menuTitle
              slug
            }
          }
          author
          datePosted(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;

export default StoriesTemplate;
