import styled from 'styled-components';

const Diamond = styled.span`
  display: block;
  margin: 1rem auto;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  background: ${props => props.theme.colors.global.primary};
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 1.875rem;
    background: ${props => props.theme.colors.global.border.light};
    position: absolute;
    transform: rotate(-45deg);
  }
  &:before {
    bottom: 100%;
    left: calc(50% - 21px);
    @media (max-width: ${props => props.theme.responsive.medium}) {
      left: calc(50% - 1.2em);
    }
  }
  &:after {
    top: 100%;
    left: calc(50% + 19px);
    @media (max-width: ${props => props.theme.responsive.medium}) {
      left: calc(50% + 1.2em);
    }
  }
`;

export default Diamond;
