import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.header};
  font-size: 1.2em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
`;

const CrumbLinks = styled(Link)`
  opacity: 0.4;
  color: ${props => props.theme.colors.global.base.light};
  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.global.base.light};
  }
`;

const CrumbDrop = styled.span`
  opacity: 1;
  color: ${props => props.theme.colors.global.base.light};
  cursor: pointer;
  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.global.base.light};
  }
`;

const Divider = styled.span`
  opacity: 0.4;
  margin: 0 0.5em;
`;

const Breadcrumbs = ({ title, type }) => (
  <Wrapper>
    <CrumbLinks to="/">Home</CrumbLinks>
    {type === 'about' && (
      <Fragment>
        <Divider>/</Divider>
        <CrumbLinks to="/about/">About</CrumbLinks>
        <Divider>/</Divider>
        <CrumbDrop>{title}</CrumbDrop>
      </Fragment>
    )}
    {type === 'properties' && (
      <Fragment>
        <Divider>/</Divider>
        <CrumbLinks to="/mw-collection/">MW Collection</CrumbLinks>
        <Divider>/</Divider>
        <CrumbDrop>{title}</CrumbDrop>
      </Fragment>
    )}
    {type === 'stories' && (
      <Fragment>
        <Divider>/</Divider>
        <CrumbDrop>{title}</CrumbDrop>
      </Fragment>
    )}
    {type === 'storyTags' && (
      <Fragment>
        <Divider>/</Divider>
        <CrumbLinks to="/stories/">Stories</CrumbLinks>
        <Divider>/</Divider>
        <CrumbDrop>{title}</CrumbDrop>
      </Fragment>
    )}
  </Wrapper>
);

export default Breadcrumbs;
