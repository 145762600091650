import React, { Fragment } from 'react';

// Components
import {
  ContentContainer,
  StoriesGrid,
  FeaturedCard,
  StoryCard,
  Pagination
} from './files';

const StoriesIndex = ({ data, pageContext }) => {
  const posts = data.allContentfulStories.edges;
  const featuredPost = posts[0].node;
  const { currentPage } = pageContext;
  const isFirstPage = currentPage === 1;

  return (
    <Fragment>
      <ContentContainer>
        {isFirstPage ? (
          <StoriesGrid className="first-page">
            <FeaturedCard className="first-page featured" {...featuredPost} />
            {posts.slice(1).map(({ node: post }) => (
              <StoryCard className="first-page" key={post.id} {...post} />
            ))}
          </StoriesGrid>
        ) : (
          <StoriesGrid>
            {posts.map(({ node: post }) => (
              <StoryCard key={post.id} {...post} />
            ))}
          </StoriesGrid>
        )}
      </ContentContainer>
      <Pagination context={pageContext} />
    </Fragment>
  );
};

export default StoriesIndex;
