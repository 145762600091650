import styled from 'styled-components';

const StoryCategoriesWrapper = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  a {
    color: ${props => props.theme.colors.global.base.light};
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.header};
    font-size: 1.125em;
    opacity: 0.4;
    margin: 0.25em 0.5em;
    position: relative;
    display: inline-block;
    transition: all 300ms ease-in-out;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1em;
    }
    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      bottom: -0.25em;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.global.primary};
    }
    &.active,
    &:hover {
      color: ${props => props.theme.colors.global.base.light};
      opacity: 1;
      &:after {
        opacity: 1;
      }
    }
  }
`;

export default StoryCategoriesWrapper;
