import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.p`
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 2em;
  padding: 0 1em;
  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 1.2em;
    margin-bottom: 3em;
  }
`;

const Description = ({ description }) => (
  <Wrapper>{description.description}</Wrapper>
);

export default Description;
