import styled from 'styled-components';

const ContentContainer = styled.div`
  padding-left: 2.5em;
  padding-right: 2.5em;
  max-width: 83.75em;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
  &.article {
    background: ${props => props.theme.colors.global.background.dark};
    position: relative;
    min-height: 50vh;
    padding: 0.75em;
    margin-top: 50vh;
    margin-bottom: 5em;
    text-align: center;
    max-width: 52em;
    @media (max-width: ${props => props.theme.responsive.large}) {
      max-width: calc(100% - 15em);
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      width: 100%;
    }
  }
`;

export default ContentContainer;
