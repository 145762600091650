import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

// Components
import { Link } from '../../../common/linkWithPrevUrl';

// Icons
import { ArrowStoriesIcon } from '../../../../assets/images/icons';

const Post = styled.div`
  margin: 0.5em;
  width: calc(25% - 1.01em);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  float: left;
  height: 18em;
  min-height: 0;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
    margin: 0.5em 0;
  }
  &:before {
    content: '';
    background: ${props => props.theme.colors.global.background.hover};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 300ms ease-in-out;
  }
  &:hover {
    &:before {
      opacity: 0.7;
    }
    .story-button {
      border: 1px solid ${props => props.theme.colors.global.primary};
      background: ${props => props.theme.colors.global.primary};
    }
    img {
      transform: scale(1.05);
    }
  }
`;

const TagLine = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 2.5rem;
  text-align: center;
  z-index: 3;
  color: ${props => props.theme.colors.global.base.light};
  font-family: ${props => props.theme.fonts.header};
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    left: 50%;
    height: 2em;
    background: ${props => props.theme.colors.global.background.light};
    top: 0;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 3;
  color: ${props => props.theme.colors.global.base.light};
  text-align: center;
  width: 100%;
  padding: 0.5em;
`;

const Title = styled.h2`
  margin-bottom: 0;
  @media (max-width: ${props => props.theme.responsive.small}) {
    margin: 0;
    font-size: 1.5em;
    line-height: 1.289473684210526;
  }
`;

const Categories = styled.h6`
  font-size: 0.9rem;
  margin: 1em 0;
  text-shadow: 0px 2px 4px ${props => props.theme.colors.global.shadow.dark};
  .divider {
    &:last-child {
      display: none;
    }
  }
`;

const Button = styled.span`
  min-width: 0;
  position: absolute;
  bottom: 1.5em;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  color: ${props => props.theme.colors.global.base.light};
  background: none;
  padding: 0.5em;
  transition: all 0.3s ease-out;
  border: 1px solid ${props => props.theme.colors.global.base.light};
  svg {
    width: 0.75em;
    height: 0.75em;
    display: block;
    polygon {
      fill: ${props => props.theme.colors.global.background.light};
    }
  }
`;

const StoryImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  img {
    transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) !important;
  }
`;

const FeaturedCard = ({ slug, featuredImage, title, categories }) => (
  <Link to={`/story/${slug}/`}>
    <Post>
      <TagLine>Latest Article</TagLine>
      <Inner>
        <Title>{title}</Title>
        <Categories>
          {categories.map(i => (
            <Fragment key={i.id}>
              {i.seoContent.menuTitle}
              <span className="divider">&nbsp;|&nbsp;</span>
            </Fragment>
          ))}
        </Categories>
      </Inner>
      <Button className="story-button">{ArrowStoriesIcon}</Button>
      <StoryImage fluid={featuredImage.fluid} backgroundColor="#111111" />
    </Post>
  </Link>
);

export default FeaturedCard;
